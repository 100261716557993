import React from 'react';
import { Link } from 'gatsby';
import { Page, Section } from 'src/components';
import paths from 'src/constants/paths';

export default function PageNotFound() {
  return (
    <Page title="Page not found" prefooter={<></>}>
      <Section
        top
        gradient
        smallTitle
        bg="var(--g-orange-light)"
        title="Sorry, we didn't catch&nbsp;that"
        subtitle={
          <div className="o50 l">
            Yes, that is a pun. No, we're not sorry.
            <br />
            We <b>are</b> sorry that we can't find the page you're looking for.
          </div>
        }
      >
        <div className="m">
          <Link to={paths.HOME}>Check out our homepage &#8594;</Link>
        </div>
      </Section>
    </Page>
  );
}
